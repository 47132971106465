export const developmentApis = {
  BASE_URL: "https://devbackend.strived.io",
  BASE_LANGCHAIN_URL: "https://apidev.strived.io",
};

export const productionApis = {
  BASE_URL: "https://prodbackend.strived.io",
  BASE_LANGCHAIN_URL: "https://apiprod.strived.io",
};

export const devApis = {
  PYTHON_BASE_URL: "https://langprodapi.strived.io",
  GOLANG_BASE_URL: "https://backend.strived.io",
};

export const APP_URL = "app.strived.io";
export const ENV = process.env.REACT_APP_ENV || "prod";
export const URL_POSTFIX = process.env.REACT_APP_URL_POSTFIX || ".strived.io";
