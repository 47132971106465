/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Dialog,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { debounce } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  bookmarkAiMessage,
  bookmarkQuestions,
  getBookmarkedMessages,
  saveAiChildRecommendations,
  // deleteSavedQuestions,
} from "../../../apis/recommendation/recommendation";
import Button from "../../../components/button";
import SioIcon from "../../../components/icons/IconLibrary";
import { DataContext } from "../../../components/layout/layout";
import { devApis } from "../../../constants/constant";
import { getUserNames } from "../../../utils";
import { customFetch } from "../../../utils/RefreshTokenInterceptor";

const SavedQuestionsSidebar = ({
  recommendationData,
  setSelectedSuggestion,
  setIsPromptEdited,
  selectedSuggestion,
  setEditedPromptData,
  setSavedQuestionsData,
  isModileSidebarActive,
  setModileSidebarIsActive,
  setSelectedIndex,
  selectedIndex,
  setFilteredRec,
}) => {
  const [savedQuestions, setSavedQuestions] = useState(null);
  const [filteredPrompts, setFilteredPrompts] = useState(null);
  const [clickedQuestion, setClickedQuestion] = useState(null);

  const [isEditing, setIsEditing] = useState(
    Array(filteredPrompts?.length).fill(false)
  );
  const [openRemoveModal, setRemoveModalOpen] = React.useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deletedQuestionContent, setDeletedQuestionContent] = useState(null);
  const [editPrompt, setEditPrompt] = useState("");

  const {
    setUpdatedQuestionsData,
    setUpdatedSaveQuestion,
    visitedSavedQuestions,
    setVisitedSavedQuestions,
  } = useContext(DataContext);

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("messageId"));

  // const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (recommendationData) {
      const sortedRocommendations = recommendationData.sort(
        (a, b) => new Date(b.saved_on) - new Date(a.saved_on)
      );

      setSelectedSuggestion(
        sortedRocommendations.filter((message) => message.id === pathId)[0]
      );

      const selectedQuestion = recommendationData.filter((question) => {
        return question.id === pathId;
      });

      handleElementClick({
        id: pathId,
        content: selectedQuestion[0]?.content,
        type: selectedQuestion[0]?.type,
      });

      setEditedPromptData(null);
    }
  }, [pathId]);

  useEffect(() => {
    const sortedRocommendations = recommendationData.sort(
      (a, b) => new Date(b.saved_on) - new Date(a.saved_on)
    );
    setSavedQuestions(sortedRocommendations);
    setFilteredPrompts(sortedRocommendations);
  }, [recommendationData]);

  useEffect(() => {
    if (recommendationData && recommendationData.length > 0)
      setSelectAll(selectedIds.length === recommendationData.length);
  }, [selectedIds, recommendationData]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleElementClick = (value) => {
    if (value.type !== "human") {
      setVisitedSavedQuestions((prev) => [...prev, value.content]);
    }
    setSelectedIndex({ id: value.id, content: value?.content });
    const newUrl = `${window.location.pathname}?messageId=${value.id}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const computeDateTime = (updated) => {
    let hours = updated.getHours();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();
    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        recommendationDate = `${updatedMonth} ${updatedDate}, ${computeDateTime(
          updated
        )}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  const handleEditClick = (ind) => {
    const newEditingState = Array(savedQuestions?.length).fill(false);
    newEditingState[ind] = true;
    setIsEditing(newEditingState);
    if (isTablet) {
      isModileSidebarActive = false;
    }
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      setSelectedIds(recommendationData.map((item) => item.id));
    }
    setSelectAll(!selectAll);
  };

  const handlePromptHistorySearch = debounce((value) => {
    setFilteredPrompts(() => {
      const data = recommendationData.filter((item) =>
        item.content.toLowerCase().includes(value.toLowerCase())
      );
      return data;
    });
  }, 300);

  const handleRecommendationsChange = (event) => {
    const value = event.target.value;
    handlePromptHistorySearch(value);
  };

  const handleSearch = (e) => {
    handleRecommendationsChange(e);
  };

  const handleRemoveModalClickOpen = (item) => {
    setDeletedQuestionContent(item);
    setRemoveModalOpen(true);
  };

  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
  };

  const handleRemoveQuestion = async () => {
    try {
      if (
        [
          "quantitative_question",
          "qualitative_question",
          "recommendation_qualitative",
          "recommendation_quantitative",
        ].includes(clickedQuestion?.type)
      ) {
        await saveAiChildRecommendations(
          [clickedQuestion],
          clickedQuestion?.type === "qualitative_question" ||
            clickedQuestion?.type === "quantitative_question"
            ? clickedQuestion?.id
            : recommendationData?.recommendation_id,
          null,
          clickedQuestion?.type
        );
        setFilteredPrompts((prevData) => {
          const updatedData = prevData.filter(
            (item) =>
              item.id !== deletedQuestionContent.id ||
              item.content !== deletedQuestionContent.content
          );
          setFilteredRec(updatedData);
          setSelectedIndex({
            id: updatedData[0]?.id,
            content: updatedData[0]?.content,
          });
          return updatedData;
        });
        const removedQuestions = JSON.parse(
          sessionStorage.getItem("removedSaveQuestions") || "[]"
        );

        if (visitedSavedQuestions.includes(deletedQuestionContent.content)) {
          removedQuestions.push(deletedQuestionContent.content);
        }

        sessionStorage.setItem(
          "removedSaveQuestions",
          JSON.stringify(removedQuestions)
        );
        const sessionSavedMessagesAPIData = JSON.parse(
          localStorage.getItem("sessionSavedMessages") || "{}"
        );
        delete sessionSavedMessagesAPIData[clickedQuestion?.content];

        const { bookmarked_messages } = await getBookmarkedMessages();

        // visitedSavedQuestions.forEach((question) => {
        //   bookmarked_messages.forEach(async (message) => {
        //     if (message.content === question) {
        //       if (message?.recommendation_id) {
        //         await bookmarkAiMessage(
        //           message.id,
        //           message.type.includes("quantitative")
        //             ? "quantitative_question"
        //             : "qualitative_question",
        //           "message_id"
        //         );
        //       } else {
        //         await bookmarkAiMessage(
        //           message.id,
        //           message.type,
        //           "recommendation_id"
        //         );
        //       }
        //     }
        //   });
        // });

        if (bookmarked_messages.length > 0) {
          const bookmarkedMessageId = bookmarked_messages.filter(
            (bookmarked) => {
              return bookmarked.content === deletedQuestionContent.content;
            }
          )[0];

          await bookmarkAiMessage(
            bookmarkedMessageId.id,
            bookmarkedMessageId.type.includes("quantitative")
              ? "quantitative_question"
              : "qualitative_question",
            bookmarkedMessageId.type.includes("recommendation")
              ? "recommendation_id"
              : "message_id"
          );
        }

        localStorage.setItem(
          "sessionSavedMessages",
          JSON.stringify(sessionSavedMessagesAPIData)
        );
      } else {
        await bookmarkQuestions(deletedQuestionContent.id);
        setFilteredPrompts((prevData) => {
          const updatedData = prevData.filter(
            (item) =>
              item.id !== deletedQuestionContent.id ||
              item.content !== deletedQuestionContent.content
          );
          setSelectedIndex({
            id: updatedData[0]?.id,
            content: updatedData[0]?.content,
          });

          setFilteredRec(updatedData);
          return updatedData;
        });
        const { bookmarked_messages } = await getBookmarkedMessages();

        if (bookmarked_messages.length > 0) {
          const bookmarkedMessageId = bookmarked_messages.filter(
            (bookmarked) => {
              return bookmarked.content === deletedQuestionContent.content;
            }
          )[0];

          if (bookmarkedMessageId) {
            await bookmarkAiMessage(
              bookmarkedMessageId?.id,
              bookmarkedMessageId.type.includes("quantitative")
                ? "quantitative_question"
                : "qualitative_question",
              bookmarkedMessageId.type.includes("recommendation")
                ? "recommendation_id"
                : "message_id"
            );
          }
        }
      }
      toast.success("The question has been removed from your saved list.", {
        icon: <SioIcon name="check" size={16} color="blue" />,
      });
    } catch (err) {
      console.error(err);
      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
    setRemoveModalOpen(false);
  };

  const editMessage = async (
    user_id,
    message_id,
    prompt,
    filename,
    session_id
  ) => {
    try {
      const res = await customFetch(`${devApis.PYTHON_BASE_URL}/edit_message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          message_id: message_id,
          new_content: prompt,
          user_id: user_id,
          filename: filename,
          child_prompt: "yes",
          session_id: session_id,
        }),
      });

      if (res.status === 200 || res.status === 201) {
        const data = await res.json();
        // setIsPromptEdited(false);
        setIsPromptEdited((prev) => {
          const newState = prev.map((item) => {
            if (item.id === message_id) {
              return { ...item, status: false };
            }
            return item;
          });
          return newState;
        });
        return data;
      } else {
        toast.error(`Error Editing prompt`, {
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        setIsPromptEdited((prev) => {
          const newState = prev.map((item) => {
            if (item.id === message_id) {
              return { ...item, status: false };
            }
            return item;
          });
          return newState;
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Error Editing prompt:", error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  /// To remove spaces at the end of text is user mistakenly adds it.
  const handleEditInputChange = (value) => {
    const withoutNbsp = value.innerHTML.replace(/&nbsp;/g, "");
    const trimmedText = withoutNbsp.trim();

    setEditPrompt(trimmedText);
  };

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  return (
    <div
      className={`flex flex-col border-r sio-sr-left-bar ${
        isModileSidebarActive ? "active" : ""
      }`}
    >
      <div className="flex items-center p-4 pt-4 md:pt-7 pb-4 relative z-10 bg-white">
        <Button
          outline
          fab
          variant="dark"
          className="flex-shrink-0 size-8 lg:size-10 visible lg:hidden"
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="leftArrow" size={14} />
        </Button>
        <Checkbox
          checked={selectAll}
          onChange={() => {
            try {
              handleSelectAllChange();
            } catch (err) {
              toast.error("Failed to select all recommendations", {
                icon: <SioIcon name="close" size={16} color="blue" />,
              });
            }
          }}
        />
        <div className="flex gap-2 items-center border rounded-full h-10 w-full px-3 me-2">
          <FiSearch size={18} className="text-xl text-grayish" />
          <input
            className="flex-grow px-3 py-2 pl-0 text-sm focus:outline-none focus:border-none"
            placeholder="Search Suggestions..."
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
      <div
        className={`overflow-y-scroll scrollbar-none sio-checkbox-list pt-1 p-5`}
      >
        {!savedQuestions
          ? Array.from({ length: 7 }).map((_, ind) => (
              <div
                key={ind}
                className={`sio-checkbox-list-item group/sio-checkbox-list-item `}
              >
                <Skeleton width={"100%"} />
                <Skeleton width={"100%"} />
                <Skeleton width={"40%"} />
              </div>
            ))
          : savedQuestions &&
            filteredPrompts?.map((value, ind) => (
              <React.Fragment key={ind}>
                <div
                  className={`sio-checkbox-list-item group/sio-checkbox-list-item ${
                    selectedIndex?.id === value.id &&
                    selectedIndex?.content === value?.content
                      ? "sio-checkbox-list-item-active"
                      : ""
                  } ${isEditing[ind] ? "" : "cursor-pointer"}`}
                  onClick={(e) => {
                    if (isTablet) {
                      setSelectedSuggestion(value);
                      setEditedPromptData(null);
                      if (!isEditing[ind]) {
                        handleModileSidebarToggle();
                      }
                    } else {
                      setSelectedSuggestion(value);
                      handleModileSidebarToggle();
                      setEditedPromptData(null);
                    }
                  }}
                >
                  <div
                    className="flex -ml-2 items-start"
                    onClick={(e) => {
                      if (value.type !== "human") {
                        setVisitedSavedQuestions((prev) => [
                          ...prev,
                          value.content,
                        ]);
                      }

                      handleElementClick(value);
                      const isChildElement = e.target.closest(".prevent-edit");

                      if (!isChildElement && pathId !== value.id) {
                        setIsEditing(
                          Array(filteredPrompts?.length).fill(false)
                        );
                      }
                    }}
                  >
                    <div className="flex-shrink-0 flex-grow-0 w-auto -mt-1 me-1">
                      <Checkbox
                        className="self-start"
                        checked={selectedIds.includes(value.id) || false}
                        onChange={() => handleCheckboxChange(value.id)}
                      />
                    </div>

                    <div className="flex-1">
                      <p
                        contentEditable={`${isEditing[ind]}`}
                        suppressContentEditableWarning={true}
                        className={`${
                          isEditing[ind] ? "" : "line-clamp-2"
                        } text-sm outline-none break-all text-secondary-dark`}
                        onInput={(e) => handleEditInputChange(e.target)}
                      >
                        {value?.content}
                      </p>
                      {!isEditing[ind] && (
                        <p className="text-[11px] text-muted-dark">
                          Saved on{" "}
                          <strong> {calculateTime(value?.saved_on)}</strong>
                        </p>
                      )}
                    </div>

                    <div
                      className={`flex-shrink-0 flex-grow-0 w-auto flex items-center relative ${
                        selectedIndex?.id !== value.id &&
                        selectedIndex?.content !== value?.content &&
                        "transition-all xl:group-hover/sio-checkbox-list-item:mr-0 xl:-mr-8"
                      } ${isEditing[ind] ? "mr-0" : "xl:-mr-8"}`}
                    >
                      {isEditing[ind] && (
                        <Button
                          variant="secondary"
                          className="soi-btn-fab"
                          onClick={async (e) => {
                            const handleSaveClick = async (value, ind) => {
                              handleModileSidebarToggle();
                              if (
                                editPrompt !== value?.content &&
                                editPrompt !== ""
                              ) {
                                setIsEditing(
                                  Array(filteredPrompts?.length).fill(false)
                                );
                                setIsPromptEdited((prev) => [
                                  ...prev,
                                  { status: true, id: value.id },
                                ]);

                                setFilteredPrompts((prevData) => {
                                  const updatedData = prevData.map((item) => {
                                    if (item.id === value.id) {
                                      return {
                                        ...item,
                                        content: editPrompt,
                                        id: value.id,
                                      };
                                    }
                                    return item;
                                  });
                                  setFilteredRec(updatedData);
                                  return updatedData;
                                });

                                const { data } = await getUserNames();
                                setUpdatedSaveQuestion(value.content);
                                const response = await editMessage(
                                  data?.user?.id,
                                  selectedSuggestion?.id,
                                  editPrompt.replace(/<[^>]*>/g, ""),
                                  selectedSuggestion?.filename,
                                  value?.session_id
                                );

                                setEditedPromptData(response);
                                setSavedQuestionsData({
                                  content: editPrompt,
                                  created_at: value?.created_at,
                                });
                                const updateData = (prevData) => {
                                  const updatedData = prevData.map((item) =>
                                    item.id === value.id
                                      ? {
                                          ...item,
                                          content: editPrompt,
                                          id: response?.message_id - 1,
                                        }
                                      : item
                                  );
                                  return updatedData.filter(
                                    (data) => data.content !== value?.content
                                  );
                                };

                                setFilteredPrompts(updateData);

                                setFilteredRec(updateData);
                                setFilteredPrompts(updateData);
                                const newUrl = `${
                                  window.location.pathname
                                }?messageId=${response?.message_id - 1}`;
                                window.history.pushState(
                                  { path: newUrl },
                                  "",
                                  newUrl
                                );

                                setUpdatedQuestionsData(true);
                              } else {
                                setIsEditing(
                                  Array(filteredPrompts?.length).fill(false)
                                );
                                setIsPromptEdited((prev) =>
                                  prev.map((item) =>
                                    item.id === value.id
                                      ? { ...item, status: false }
                                      : item
                                  )
                                );
                              }
                            };

                            handleModileSidebarToggle();
                            if (
                              editPrompt !== value?.content &&
                              editPrompt !== ""
                            ) {
                              handleSaveClick(value, ind);
                            } else {
                              setIsEditing(
                                Array(filteredPrompts?.length).fill(false)
                              );
                              setIsPromptEdited((prev) =>
                                prev.map((item) =>
                                  item.id === value.id
                                    ? { ...item, status: false }
                                    : item
                                )
                              );
                            }
                          }}
                        >
                          <SioIcon name="saveCheck" size={16} />
                        </Button>
                      )}
                      {!isEditing[ind] && (
                        <>
                          <Tooltip title="Edit">
                            <button
                              className="soi-btn soi-btn-fab soi-btn-flat btn-dark prevent-edit"
                              onClick={(e) => {
                                if (
                                  visitedSavedQuestions.includes(value?.content)
                                ) {
                                  toast(
                                    "Response is in progress you can't Edit the question."
                                  );
                                  return;
                                }
                                handleEditClick(ind);
                              }}
                            >
                              <SioIcon name="edit" size={16} />
                            </button>
                          </Tooltip>
                          <Tooltip title="Remove">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  visitedSavedQuestions.includes(value?.content)
                                ) {
                                  toast(
                                    "Response is in progress you can't Unsave the question."
                                  );
                                  return;
                                }
                                handleRemoveModalClickOpen(value);
                                setClickedQuestion(value);
                              }}
                              className={`soi-btn soi-btn-fab visible xl:invisible soi-btn-flat btn-dark ${
                                selectedIndex?.id === value.id &&
                                selectedIndex?.content === value?.content
                                  ? "visible xl:invisible"
                                  : "group-hover/sio-checkbox-list-item:visible"
                              }`}
                            >
                              <SioIcon name="saved" size={18} />
                            </button>
                          </Tooltip>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
      </div>
      <Dialog
        open={openRemoveModal}
        onClose={() => handleRemoveModalClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
          <SioIcon name="delete" size={80} className="mb-5" />
          <h1 className="text-primary-dark text-xl mb-7">
            Are you sure you want to remove saved question?
          </h1>
          <div className="gap-4 flex">
            <Button
              variant="secondary"
              onClick={() => {
                handleRemoveQuestion();
                setUpdatedQuestionsData(true);
              }}
              size={"sm"}
            >
              Yes, Remove
            </Button>
            <Button
              size={"sm"}
              onClick={() => handleRemoveModalClose()}
              className="text-black"
            >
              Not Now
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SavedQuestionsSidebar;
