/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import SavedQuestionsSidebar from "./savedQuestionsSidebar";
import SavedQuestionsContainer from "./savedQuestionsContainer";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import {
  addMessageToSession,
  bookmarkQuestions,
  getSavedQuestions,
  getFilesList,
  deleteAllSavedMessages,
  getBookmarkedMessages,
} from "../../../apis/recommendation/recommendation";
import { DataContext } from "../../../components/layout/layout";
import {
  Checkbox,
  CircularProgress,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { devApis } from "../../../constants/constant";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import SioIcon from "../../../components/icons/IconLibrary";
import { customFetch } from "../../../utils/RefreshTokenInterceptor";

const SavedQuestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);

  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [editedPromptData, setEditedPromptData] = useState(null);
  const [savedQuestionsData, setSavedQuestionsData] = useState(null);

  const [filesList, setFilesList] = useState([]);

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("messageId"));

  const {
    setHighlightField,
    filesListData,
    updatedQuestionsData,
    setUpdatedQuestionsData,
    addingNewQuestion,
    setAddingNewQuestion,
    filteredRec,
    setFilteredRec,
    setNewlyAddedQuestion,
    customPrompt,
    setCustomPrompt,
    newQuestionsDataSourceFilter,
    setNewQuestionsDataSourceFilter,
    openAddNewQuationDrawer,
    setAddNewQuationDrawerOpen,
    handleAddNewQuestion,
    newlyAddedQuestion,
    isPromptEdited,
    setIsPromptEdited,
    updatedSaveQuestion,
    selectedIndex,
    setSelectedIndex,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const savedQuestionsAPIFunction = async () => {
    const removedQuestions = JSON.parse(
      sessionStorage.getItem("removedSaveQuestions") || "[]"
    );

    const { saved_messages } = await getSavedQuestions();

    const sortedMessages = saved_messages.sort(
      (a, b) => new Date(b.saved_on) - new Date(a.saved_on)
    );

    if (removedQuestions.length > 0) {
      const newQuestions = sortedMessages.filter(
        (message) => !removedQuestions.includes(message.content)
      );
      setFilteredRec(newQuestions);
      sortedMessages.forEach(async (message) => {
        if (
          removedQuestions.includes(message.content) &&
          message.type === "human"
        ) {
          const filteredRemovedQuestions = removedQuestions.filter(
            (question) => question !== message.content
          );

          sessionStorage.setItem(
            "removedSaveQuestions",
            JSON.stringify(filteredRemovedQuestions)
          );
          await bookmarkQuestions(message.id);
        }
      });
    } else {
      if (sortedMessages.length > 0) {
        setFilteredRec((prev) => {
          const uniqueData = [];
          const data = [...prev, ...saved_messages];
          const seenIds = new Set();

          for (const item of data) {
            if (!seenIds.has(item.content)) {
              seenIds.add(item.content);
              uniqueData.push(item);
            }
          }
          if (isPromptEdited.filter((item) => item.status).length > 0) {
            return uniqueData.filter(
              (message) => message.content !== updatedSaveQuestion
            );
          } else {
            return uniqueData;
          }
        });
      } else {
        setFilteredRec([]);
      }
    }

    const selectedQuestionData = sortedMessages.filter(
      (message) => message.id === pathId
    )[0];
    let newUrl;

    if (pathId === 0 && !pathId) {
      if (sortedMessages.length > 0) {
        newUrl = `${window.location.pathname}?messageId=${
          sortedMessages.filter(
            (message) => !removedQuestions.includes(message.content)
          )[0]?.id
        }`;
      } else {
        newUrl = `${window.location.pathname}`;
      }
      window.history.pushState({ path: newUrl }, "", newUrl);
    } else {
      const selectedQuestion = sortedMessages.filter((question) => {
        return question.id === pathId;
      });
      setSelectedIndex({ id: pathId, content: selectedQuestion[0]?.content });

      if (sortedMessages.length > 0) {
        newUrl = `${window.location.pathname}?messageId=${selectedQuestionData?.id}`;
      } else {
        newUrl = `${window.location.pathname}`;
      }
      window.history.pushState({ path: newUrl }, "", newUrl);

      setSelectedSuggestion(selectedQuestionData);
    }
    setUpdatedQuestionsData(null);
  };

  const getFileNames = async () => {
    const files = await getFilesList();
    const filteredFileData = files.filter((file) =>
      file.original_file_name.includes(".csv")
    );
    setFilesList(filteredFileData.filter((file) => !file.archive_status));
  };

  useEffect(() => {
    getFileNames();
    savedQuestionsAPIFunction();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("History");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    setHighlightField("History");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      const newUrl = `${window.location.pathname}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    };
  }, []);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const formatValidDate = (dateStr) => {
    let date = new Date(dateStr);
    if (isNaN(date)) date = new Date();
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(" ", " , ");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex items-center shadow-md p-3 md:p-5 relative z-10">
        <Button
          outline
          fab
          variant="dark"
          className="me-2 size-8 flex lg:hidden"
          size={"xs"}
          onClick={handleModileSidebarToggle}
        >
          <SioIcon name="filter" size={18} />
        </Button>
        <h1
          className="font-normal text-lg md:text-xl text-primary-dark"
          // onClick={() => {
          //   setFilteredRec([]);
          //   deleteAllSavedMessages();
          // }}
        >
          Saved Questions
        </h1>
        <div className="items-center ms-auto flex gap-5">
          <Button
            variant="default"
            className="hidden lg:flex"
            size={"sm"}
            onClick={toggleAddNewQuationDrawer(true)}
          >
            Add New
          </Button>
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  const toggleAddNewQuationDrawer = (newOpen) => () => {
    setAddNewQuationDrawerOpen(newOpen);
    setCustomPrompt("");
  };

  const handleInput = (event) => {
    const { value } = event.target;
    setCustomPrompt(value);
  };

  const debouncedInputHandler = handleInput;

  const inputRef = useRef(null);

  const handlePaste = (event) => {
    event.preventDefault();

    const clipboardText = event.clipboardData.getData("text");
    const cleanedText = clipboardText
      .split("\n")
      .filter((line) => line.trim() !== "")
      .join("\n")
      .trim();

    if (inputRef.current) {
      const textarea = inputRef.current.querySelector("textarea");
      if (textarea) {
        const { selectionStart, selectionEnd } = textarea;

        const currentValue = customPrompt || "";

        const newValue =
          currentValue.substring(0, selectionStart) +
          cleanedText +
          currentValue.substring(selectionEnd);

        setCustomPrompt(newValue);

        textarea.value = newValue;

        textarea.dispatchEvent(new Event("input", { bubbles: true }));
      }
    }
  };

  // Select Data Source Filter

  // Add new questions Data Source

  const handleChangNewQuaDataSourceFilter = (event, item) => {
    if (event.target.checked) {
      setNewQuestionsDataSourceFilter((prev) => [...prev, item]);
    } else {
      setNewQuestionsDataSourceFilter((prev) =>
        prev.filter((i) => i.original_file_name !== item.original_file_name)
      );
    }
  };
  const handleDataSourceItemClick = (item) => {
    if (
      newQuestionsDataSourceFilter.some(
        (selected) => selected.original_file_name === item.original_file_name
      )
    ) {
      setNewQuestionsDataSourceFilter((prev) =>
        prev.filter(
          (selected) => selected.original_file_name !== item.original_file_name
        )
      );
    } else {
      setNewQuestionsDataSourceFilter((prev) => [...prev, item]);
    }
  };

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      {filteredRec?.length === 0 ? (
        <div className="flex flex-col items-center justify-center gap-3 h-full">
          <i className="pb-5">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle opacity="0.1" cx="40" cy="40" r="40" fill="#111111" />
              <path
                opacity="0.54"
                d="M31.904 24C29.1251 24 27 26.2354 27 28.9499V52.5815C27 53.8589 27.8173 55.1362 28.9616 55.6153C29.452 55.7749 29.7789 55.9346 30.2693 55.9346C31.0867 55.9346 31.7405 55.6153 32.3944 55.1362L39.9138 48.2703L47.5968 55.1362C48.5776 55.9346 49.8853 56.254 51.0296 55.7749C52.1738 55.2959 52.9912 54.0185 52.9912 52.7411V28.9499C53.1546 26.2354 51.0296 24 48.2506 24H31.904ZM49.8853 28.9499V52.7411L42.2024 45.8752C41.5485 45.3962 40.7312 45.0768 40.0773 45.0768C39.26 45.0768 38.6061 45.3962 37.9523 45.8752L30.2693 52.5815V28.9499C30.2693 27.9918 30.9232 27.1935 31.904 27.1935H48.2506C49.2314 27.1935 49.8853 27.9918 49.8853 28.9499Z"
                fill="#111111"
                fill-opacity="0.74"
              />
            </svg>
          </i>
          <h1 className="text-primary-dark text-3xl font-semibold">
            You have no saved questions yet!
          </h1>
          <div className="flex items-center p-3 md:p-5 relative z-10">
            <Button
              outline
              fab
              variant="dark"
              className="me-2 size-8 flex lg:hidden"
              size={"xs"}
              onClick={handleModileSidebarToggle}
            >
              <SioIcon name="filter" size={18} />
            </Button>

            <div className="items-center ms-auto flex gap-5">
              <Button
                variant="default"
                className="hidden lg:flex"
                size={"sm"}
                onClick={toggleAddNewQuationDrawer(true)}
              >
                Add New
              </Button>
              <Button
                variant="secondary"
                className="hidden lg:flex"
                size={"sm"}
                onClick={() => {
                  handleAskMeAnything();
                }}
              >
                Ask me anything
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={`h-full w-full flex flex-col overflow-hidden`}>
            {playGroundTopBar()}
            <div className={`flex flex-row overflow-hidden h-full`}>
              <SavedQuestionsSidebar
                recommendationData={filteredRec}
                setSelectedSuggestion={setSelectedSuggestion}
                setIsPromptEdited={setIsPromptEdited}
                selectedSuggestion={selectedSuggestion}
                setEditedPromptData={setEditedPromptData}
                setSavedQuestionsData={setSavedQuestionsData}
                setModileSidebarIsActive={setModileSidebarIsActive}
                isModileSidebarActive={isModileSidebarActive}
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                setFilteredRec={setFilteredRec}
              />
              <SavedQuestionsContainer
                recommendationData={selectedSuggestion}
                setSelectedSuggestion={setSelectedSuggestion}
                isPromptEdited={isPromptEdited}
                editedPromptData={editedPromptData}
                savedQuestionsData={savedQuestionsData}
                setSavedQuestionsData={setSavedQuestionsData}
                setSelectedIndex={setSelectedIndex}
                suggestionsData={filteredRec}
                selectedIndex={selectedIndex}
                setFilteredRec={setFilteredRec}
                filteredRec={filteredRec}
              />
            </div>
          </div>
        </>
      )}
      <Drawer
        anchor={"right"}
        open={openAddNewQuationDrawer}
        onClose={toggleAddNewQuationDrawer(false)}
      >
        <div className="sio-sidebar-right w-[300px] md:w-[500px]">
          <div className="sio-sidebar-header flex items-center">
            <h2>Add New Question</h2>
            <Button
              className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
              onClick={toggleAddNewQuationDrawer(false)}
            >
              <SioIcon name="closeLight" />
            </Button>
          </div>

          {addingNewQuestion ? (
            <div
              className="flex flex-col justify-start items-center gap-10 text-md 
                    font-semibold w-full h-full pt-20"
            >
              <svg width={0} height={0}>
                <defs>
                  <linearGradient
                    id="my_gradient"
                    x1="0%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#e01cd5" />
                    <stop offset="100%" stopColor="#1CB5E0" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress
                sx={{
                  "svg circle": { stroke: "url(#my_gradient)" },
                  size: "20px",
                }}
              />
              <h3>Please wait while we adding the question.</h3>
            </div>
          ) : (
            <>
              <div className="sio-sidebar-body h-auto p-6 !py-7">
                <div className="grid grid-cols-1 gap-y-6">
                  <TextField
                    label="Question"
                    ref={inputRef}
                    multiline
                    rows={4}
                    fullWidth
                    value={customPrompt}
                    onPaste={handlePaste}
                    onChange={debouncedInputHandler}
                  />
                  {filesList && filesList.length > 0 && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-chip-label">
                        Data Source
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        // displayEmpty
                        value={newQuestionsDataSourceFilter}
                        onChange={handleChangNewQuaDataSourceFilter}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip-label"
                            className="sio-select"
                            label="Data Source"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: { width: "auto" },
                          },
                          MenuListProps: {
                            className: "sio-menu-check-list",
                          },
                        }}
                        renderValue={(selected) => (
                          <div
                            className="flex items-center text-sm text-secondary-dark"
                            dangerouslySetInnerHTML={{
                              __html:
                                selected.length > 0
                                  ? `${selected.length} Data Source(s) selected`
                                  : "",
                            }}
                          />
                        )}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {filesList.map((file) => (
                          <MenuItem
                            key={file.id}
                            value={file}
                            onClick={() => handleDataSourceItemClick(file)}
                            className="sio-menu-item-align-top"
                          >
                            <Checkbox
                              checked={newQuestionsDataSourceFilter.some(
                                (selected) =>
                                  selected.original_file_name ===
                                  file.original_file_name
                              )}
                              tabIndex={-1}
                            />
                            <div>
                              <h6 className="sio-menu-item-title break-all">
                                {file.original_file_name}
                              </h6>
                              <p className="sio-menu-item-subtitle">
                                Uploaded on {formatValidDate(file.created_at)}
                              </p>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>

              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  size={"sm"}
                  onClick={async () => {
                    setSavedQuestionsData({
                      content: customPrompt,
                      created_at: new Date().getUTCDate(),
                    });
                    let newUrl;
                    // window.history.pushState({ path: newUrl }, "", newUrl);

                    const newlyAddedQuestionId = await handleAddNewQuestion();

                    newUrl = `${window.location.pathname}?messageId=${newlyAddedQuestionId}`;
                    window.history.pushState({ path: newUrl }, "", newUrl);
                    await savedQuestionsAPIFunction();
                  }}
                >
                  {" "}
                  Save Question{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={() => {
                    setAddNewQuationDrawerOpen(false);
                    setNewQuestionsDataSourceFilter([]);
                  }}
                  size={"sm"}
                  className="text-black"
                >
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default SavedQuestions;
